@import "../../../data/styles.css";

.article {
    display: flex;
}

    .article a {
        text-decoration: none;
    }

.article-right-side:hover {
    background: #fafafa;
    opacity: 1;
    transition: background-color 0.3s ease-in-out;
}

    .article-right-side:hover .article-link {
        color: var(--link-color);
        transition: color 0.3s ease-in-out;
    }

.article-left-side {
    min-width: 20%;
    max-width: 20%;
}

.article-right-side {
    flex-basis: 80%;
    min-width: 700px;
    mix-blend-mode: normal;
    border-radius: 20px;
    opacity: 0.8;
    padding: 30px;
}

.article-date {
    padding-top: 30px;
    font-size: 15px;
    color: rgb(201, 251, 255)
}

.article-title {
    color: #000000;
    font-size: 16px;
    font-weight: 600;
}

.article-subtitle {
    color: floralwhite;
    font-size: 16px;
    font-weight: 600;
}

.article-right-side:hover .article-subtitle {
    color: #6d6d6d;
}

.article-description {
    padding-top: 10px;
    font-size: 15px;
    color: #c9fbff;
    font-weight: 480;
    line-height: 25px;
}

.article-link {
    padding-top: 10px;
    font-size: 14px;
    color: var(--secondary-color);
    font-weight: 700;
}

.article-right-side:hover .article-description {
    color: #65656d;
    transition: color 0.3s ease-in-out;
    margin: 1.5em 0 2em 0
}

@media (max-width: 1024px) {
    .article-left-side {
        min-width: 10%;
        max-width: 10%;
    }

    .article-right-side {
        flex-basis: 90%;
        min-width: 85%;
        max-width: 85%;
    }
}
