/* General Page Styles */
.achievements-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px;
    font-family: 'Roboto', sans-serif;
}

.achievements-title {
    padding: 50px;
    font-size: 45px;
    font-weight: 700;
    font-family: "Bebas Neue", sans-serif;
    margin-bottom: 0px;
    color: var(--primary-color);
    text-align: center;
}

/* Container for Cards */
.achievements-container {
    display: grid;
    grid-template-columns: repeat(3, minmax(400px, 1fr)); /* Dynamically adjust cards per row */
    gap: 90px;
    width: 100%;
    max-width: 1500px;
}

/* Individual Card Styles */
.card {
    perspective: 1000px;
}

.card-inner {
    position: relative;
    width: 100%;
    height: 300px;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    transform-origin: center; /* Ensure the card rotates around its center */
}

.card:hover .card-inner {
    transform: rotateY(180deg);
}

.card-front,
.card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /*padding: 20px;*/
    font-size: 1.3rem;
    font-weight: bold;
    color: #333;
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background: #fff;
}

.card-front {
    text-align: center;
}

.card-back {
    transform: rotateY(180deg);
    text-align: center;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.6;
    padding: 10px;
    color: #444;
    background: linear-gradient(to bottom right, #ffffff, #f9f9f9);
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease-in-out; /* Smooth transitions for flip effects */
}

/* Specific Colors */
.card-front.color1 {
    background-color: rgb(6, 214, 160);
}

.card-front.color2 {
    background-color: rgb(249, 199, 79);
}

.card-front.color3 {
    background-color: rgb(201, 251, 255);
}

/* Icon Style */
.card-icon {
    font-size: 5.5rem;
    margin-bottom: 15px;
    color: #555;
}

/* Responsive Design */
@media (max-width: 768px) {
    .achievements-container {
        grid-template-columns: repeat(2, 1fr); /* 2 cards per row on smaller screens */
    }
}

@media (max-width: 480px) {
    .achievements-container {
        grid-template-columns: 1fr; /* 1 card per row on very small screens */
    }
}

.achievements-logo-container {
    display: flex;
    justify-content: left;
    padding-top: 25px;
}

.achievements-logo {
    display: flex;
    position: fixed;
    border: 1px solid white;
    border-radius: 50%;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    top: 4vh;
    z-index: 999;
}
