@import "../../data/styles.css";

.homepage-logo-container {
    display: flex;
    justify-content: left;
    align-items: center;
    padding-top: 130px;
}

.homepage-container {
    padding-top: 25px;
}

.homepage-first-area {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.homepage-first-area-left-side {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.homepage-title {
    width: 85% !important;
}

.homepage-subtitle {
    width: 85% !important;
}

.homepage-first-area-right-side {
    display: flex;
    align-items: center;
}

.homepage-image-container {
    width: 370px;
    height: 370px;
}

.homepage-image-wrapper {
    overflow: hidden;
    border-radius: 10%;
    transform: rotate(3deg);
}

    .homepage-image-wrapper img {
        width: 100%;
    }

.homepage-socials {
    display: flex;
    justify-content: left;
    align-items: center;
    padding-top: 30px;
}

.homepage-social-icon {
    padding-right: 20px;
    font-size: 25px;
    transition: color 0.2s ease-in-out;
    color: var(--secondary-color);
}

    .homepage-social-icon:hover {
        color: var(--primary-color);
    }

.homepage-after-title {
    display: flex;
}

.homepage-projects {
    flex-basis: 300px;
    padding-top: 40px;
}

.homepage-articles {
    flex-basis: 900px;
    padding-top: 30px;
    margin-left: -30px;
}

.homepage-article {
    padding-bottom: 5px;
}

.homepage-works {
    flex-basis: 600px;
    padding-top: 30px;
}

@media (max-width: 1024px) {
    .homepage-first-area {
        flex-direction: column;
    }

    .homepage-first-area-left-side {
        display: flex;
        flex-direction: column; /* Changed: set direction to column */
        align-items: flex-start;
        width: 100%;
        order: 2;
    }

    .homepage-title {
        width: 100% !important;
        margin-top: 20px;
        order: 2;
        padding: 50px;
        font-size: 45px;
        font-weight: 700;
        font-family: "Bebas Neue", sans-serif;
        margin-bottom: 0px;
        color: var(--primary-color);
        text-align: center;
    }

    .homepage-subtitle {
        width: 100% !important;
        margin-top: 10px;
        order: 3;
    }

    .homepage-first-area-right-side {
        width: 100%;
        order: 1;
    }

    .homepage-image-container {
        padding-top: 50px;
        padding-bottom: 50px;
        margin-left: 10px;
        order: 1;
    }

    .homepage-after-title {
        flex-direction: column;
    }

    .homepage-projects {
        flex-basis: auto;
    }

    .homepage-articles {
        flex-basis: auto;
    }

    .homepage-works {
        flex-basis: auto;
        margin-left: -25px;
        margin-right: -25px;
    }
}

.title {
    font-size: 15em;
    text-align: center;
    font-family: "Bebas Neue", sans-serif;
}

h3 {
    padding-top: 0.25em;
}

.vertical-timeline-element-content {
    box-shadow: 0 0.25em 0.5em 0 rgba(0,0,0,0.25), 0 0 0.4em 1.25em 0 rgba(0,0,0,0.15) !important;
    padding: 2em 3em !important;
}

.date {
    color: rgb(201, 251, 255)
}

#description {
    margin: 1.5em 0 2em 0
}

.button {
    text-decoration: none;
    padding: 0.5em 1em;
    border-radius: 5px;
    color: white
}

.workButton {
    background-color: #06d6a0;
}

    .workButton:hover {
        background-color: #0ac593;
    }

.schoolButton {
    background-color: #f9c74f;
}

    .schoolButton:hover {
        background-color: #f3bc3c;
    }

@media only screen and (max-width: 1200px){
    .vertical-timeline-element-date{
        display: block !important;
        float: none !important;
        color: rgb(44, 44, 44);
        margin-top: 1.5em
    }
}

.tech-stack {
    text-align: center;
    margin: 2rem 0;
}

.tech-stack-grid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 3rem;
    justify-items: center;
    align-items: center;
    padding: 1rem;
}

.tech-item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.tech-logo {
    width: 80px;
    height: 80px;
    object-fit: contain;
    transition: transform 0.3s ease-in-out;
}

.tech-logo:hover {
    transform: scale(1.1);
}

.tech-tooltip {
    position: absolute;
    bottom: -40px;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.75);
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 0.8rem;
    white-space: nowrap;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease-in-out;
    z-index: 10;
}

.tech-item:hover .tech-tooltip {
    opacity: 1;
    visibility: visible;
}


