@import "../../../data/styles.css";

.project {
    mix-blend-mode: normal;
    border-radius: 20px;
    opacity: 0.8;
    height: 100%;
}

    .project a {
        text-decoration: none;
    }

    .project:hover {
        background: #fafafa;
        opacity: 1;
        transition: background-color 0.3s ease-in-out;
    }

        .project:hover .project-link {
            color: var(--link-color);
            transition: color 0.3s ease-in-out;
        }

.project-container {
    padding: 30px;
}

.project-logo {
    width: 30px;
}

    .project-logo img {
        width: 100%;
    }

.project-title {
    font-family: var(--secondary-font);
    color: var(--primary-color);
    font-size: 16px;
    padding-top: 15px;
    margin-bottom: 1em;
    font-weight: 700;
}

.project-description {
    color: rgb(201, 251, 255);
    font-size: 15px;
}

.project:hover .project-description {
    color: #65656d;
    transition: color 0.3s ease-in-out;
    margin: 1.5em 0 2em 0
}

.project-link {
    display: flex;
    align-items: center;
    color: var(--secondary-color);
    font-size: 12px;
    padding-top: 20px;
}

.project-link-icon {
    padding-left: 5px;
    font-size: 13px;
}

.project-link-text {
    padding-left: 20px;
    font-weight: 700;
}

@media (max-width: 600px) {
    .project {
        height: 300px;
    }
}
