:root {
    /* ------- colors ------- */
    --primary-color: #27272a;
    --secondary-color: #65656d;
    --tertiary-color: #acacb4;
    --quaternary-color: #e4e4e7;
    --link-color: #14b8a6;
    /* ---------------------- */
    /* ------- fonts ------- */
    --primary-font: "Heebo", sans-serif;
    --secondary-font: "Roboto", sans-serif;
    /* --------------------- */
}
