.about-logo-container {
    display: flex;
    justify-content: left;
    padding-top: 25px;
}

.about-logo {
    display: flex;
    position: fixed;
    border: 1px solid white;
    border-radius: 50%;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    top: 4vh;
}

.about-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin: 0;
    padding-top: 120px;
}

.about-main {
    display: flex;
}

.about-right-side {
    flex-basis: 900px;
}

.about-title {
    width: 100% !important;
}

.about-subtitle {
    width: 80% !important;
}

.about-left-side {
    display: flex;
    flex-direction: column;
    flex-basis: 300px;
}

.about-image-container {
    width: 370px;
}

.about-image-wrapper {
    display: inline-block;
    overflow: hidden;
    width: 100%;
    border-radius: 10%;
    transform: rotate(3deg);
}

    .about-image-wrapper img {
        display: block;
        width: 100%;
    }

.about-socials {
    padding-top: 50px;
    padding-bottom: 50px;
}

.about-socials-mobile {
    display: none;
}

@media (max-width: 1024px) {
    .about-logo {
        z-index: 100;
    }

    .about-main {
        flex-direction: column;
    }

    .about-subtitle {
        padding-top: 20px !important;
    }

    .about-left-side {
        flex-basis: 100%;
        order: 1;
    }

    .about-right-side {
        flex-basis: 100%;
        order: 2;
    }

    .about-image-container {
        padding-bottom: 40px;
    }

    .about-socials {
        display: none;
    }

    .about-socials-mobile {
        padding-top: 30px;
        display: flex;
    }
}

@media (max-width: 600px) {
    .about-container {
        padding-top: 60px;
    }

    .about-image-container {
        width: 80%;
    }
}
