@import "/src/data/styles.css";

::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    background-color: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background-color: #383636;
    border-radius: 5px;
}

    ::-webkit-scrollbar-thumb:hover {
        background-color: rgb(0, 0, 0);
    }

.page-content {
    background-color: #3da3d5;
    position: relative;
    min-height: 100vh;
}

.flex-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.content-wrapper {
    flex: 1; /* This will make it fill the available space */
    max-width: 1300px;
    margin: auto; /* Centers the content-wrapper horizontally */
    padding: 20px; /* Optional padding */
}

.title {
    color: var(--primary-color);
    font-size: 15em;
    text-align: center;
    font-family: "Bebas Neue", sans-serif;
    font-size: 45px;
    font-weight: 700;
    width: 100%;
}

.subtitle {
    padding-top: 10px;
    color: rgb(201, 251, 255);
    font-size: 16px;
    line-height: 28px;
    width: 70%;
    margin-top: 25px;
}

.page-footer {
    width: 100%;
}

    .page-footer::before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        border-top: 2px solid var(--tertiary-color);
    }

@media (max-width: 1270px) {

    .content-wrapper {
        flex: 1; /* This will make it fill the available space */
        max-width: 1000px;
        margin: auto; /* Centers the content-wrapper horizontally */
        padding: 20px; /* Optional padding */
    }
}

@media (max-width: 1024px) {

    .content-wrapper {
        flex: 1; /* This will make it fill the available space */
        max-width: 1000px;
        margin: auto; /* Centers the content-wrapper horizontally */
        padding: 20px; /* Optional padding */
    }
}

@media (max-width: 800px) {
    .content-wrapper {
        flex: 1; /* This will make it fill the available space */
        max-width: 1000px;
        margin: auto; /* Centers the content-wrapper horizontally */
        padding: 20px; /* Optional padding */
    }

    .title {
        width: 100%;
    }

    .subtitle {
        width: 100%;
    }
}
